import type { YYYYMM } from '@dendra/utils';
import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  EntityState,
  EntityStore,
  QueryEntity,
  StoreConfig,
  MultiActiveState,
  QueryConfig,
  Order,
} from '@datorama/akita';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { MultiPolygon, Polygon } from '@turf/helpers';
import { Observable, of, tap, map } from 'rxjs';

export type Survey = {
  id: number;
  display_name: string;
  date: YYYYMM;
  location: number;
  survey_type: 'full-survey' | 'subsite';
  area: number;
};

export type Methodology = {
  date: YYYYMM;
  methodology: 'WEEDS_L1' | 'WEEDS_L3';
  analysis_type: 'weeds';
};

export const MethodologyDescriptions = {
  WEEDS_L1:
    'Sampled assessment: each infestation is a ~10x10m quadrat containing at least one weed.',
  WEEDS_L3:
    'Each ~2x2m quadrat containing at least one weed is tagged within the survey boundary.',
};

interface SurveyState extends EntityState<Survey, number>, MultiActiveState {}

@StoreConfig({ name: 'survey' })
@Injectable({ providedIn: 'root' })
export class SurveyStore extends EntityStore<SurveyState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class SurveyQuery extends QueryEntity<SurveyState> {
  protected store: SurveyStore;

  constructor() {
    const store = inject(SurveyStore);

    super(store);
    this.store = store;
  }
}

/* Survey Boundary store and query */
export type SurveyBoundary = {
  id: number;
  geometry: Polygon | MultiPolygon | null;
};

interface SurveyBoundaryState extends EntityState<SurveyBoundary, number> {}

@StoreConfig({ name: 'survey-boundary' })
@Injectable({ providedIn: 'root' })
export class SurveyBoundaryStore extends EntityStore<SurveyBoundaryState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'date', sortByOrder: Order.DESC })
export class SurveyBoundaryQuery extends QueryEntity<SurveyBoundaryState> {
  protected store: SurveyBoundaryStore;

  constructor() {
    const store = inject(SurveyBoundaryStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class SurveyService extends NgEntityService<SurveyState> {
  protected store: SurveyStore;
  query = inject(SurveyQuery);
  protected boundaryStore = inject(SurveyBoundaryStore);
  boundaryQuery = inject(SurveyBoundaryQuery);

  constructor() {
    const store = inject(SurveyStore);

    super(store);
    this.store = store;
  }

  lazyGet(id: number): Observable<Survey> {
    return this.query.hasEntity(id)
      ? of(this.query.getEntity(id))
      : this.get(id);
  }

  lazyFetchForLocation(locationId: number) {
    const matchesLocation = (survey: Survey) => survey.location === locationId;

    return this.query.hasEntity(matchesLocation)
      ? of(
          this.query.getAll({
            filterBy: matchesLocation,
          }),
        )
      : this.fetchForLocation(locationId);
  }

  fetchForLocation(locationId: number) {
    return this.get({
      params: new HttpParams({
        fromObject: {
          location: locationId,
          skai_features__customer_facing: true,
        },
      }),
      mapResponseFn: res => res.results,
    });
  }

  selectForLocation(locationId: number) {
    return this.query.selectAll({
      filterBy: survey => survey.location === locationId,
    });
  }

  getBoundary(id: number) {
    const entity = this.boundaryQuery.getEntity(id);
    if (entity) {
      return of(entity);
    }

    return this.getHttp()
      .get<SurveyBoundary>(this.resolveUrl({ urlPostfix: 'boundary/' }, id))
      .pipe(tap(boundary => this.boundaryStore.add(boundary)));
  }

  getLatestSurveyForLocation(locationId: number) {
    return this.lazyFetchForLocation(locationId).pipe(
      map(surveys => surveys.at(0) ?? null),
    );
  }

  getMethodologiesForLocation(locationId: number) {
    return this.getHttp().get<Methodology[]>(
      this.resolveUrl({ urlPostfix: 'methodologies/' }),
      { params: { location: locationId } },
    );
  }
}
